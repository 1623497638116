import React from 'react';
import img from '../img/about.jpg'; 
import '../style/style.css';


const About = (props) => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="about-content">
          <div className="about-text">
            <h2>{props.data.Header ? props.data.Header.title : "Maralu s.r.o."}</h2>
            <p>{props.data.About ? props.data.About.paragraph : "loading..."}</p>
            <button href="#contact" className="about-button">Kontaktujte nás</button>
          </div>
          <div className="about-image">
            <img src={img} alt="About Us" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
