import { useEffect, useState } from 'react';
import axios from 'axios';
import '../style/style.css';
import Navigation from "../components/navigation.jsx";
import Footer from "../components/footer";
import JsonData from "../data/data.js";

export const ApiData = () => {
    const [name, setName] = useState('');
    const [data, setData] = useState('');
    const [landingPageData, setLandingPageData] = useState({});

    useEffect(() => {
        if (name) {
            axios.get(`https://api.agify.io/?name=${name}`).then((response) => {
                setData(response.data.age);
            });
        }
    }, [name]);

    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div>
            <Navigation data={landingPageData.Items} />
            <div id="api" className="text-center">
                <div className="section-title text-center">
                    <h2>Api</h2>
                </div>
                <div className="form">
                    <h2>Meno: {name} má {data} rokov.</h2>
                    <label htmlFor="name">Meno:</label>
                    <input
                        type="text"
                        placeholder='Ex. Pedro...'
                        onChange={(e) => setName(e.target.value)}
                    />
                    <button onClick={() => setName(name)}>Vypočítať</button>
                </div>
            </div>
            <Footer items={landingPageData.Items} contact={landingPageData.Contact} />
        </div>
    );
};
