import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navigation from "./components/navigation";
import Header from "./components/header";
import Uctovnictvo from "./components/uctovnictvo";
import About from "./components/about";
import Autodoprava from "./components/autodoprava";
import Gallery from "./components/gallery";
import Klienti from "./components/klienti";
import Contact from "./components/contact";
import CookieConsent from './components/cookies';
import Footer from "./components/footer";
import { ApiData } from "./pages/apiData";

import data from "./data/data";
import './style/style.css';
import "primeflex/primeflex.css";
import 'primeicons/primeicons.css';
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(data);
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigation data={landingPageData.Items} />
                <Header data={landingPageData.Header} />
                <Uctovnictvo data={landingPageData.Uctovnictvo} />
                <Autodoprava data={landingPageData.Autodoprava} />
                <About data={landingPageData} />
                <Gallery data={landingPageData.Gallery} />
                <Klienti data={landingPageData.Klienti} />
                <Contact data={landingPageData.Contact} />
                <Footer items={landingPageData.Items} contact={landingPageData.Contact} />
                <CookieConsent />
              </>
            }
          />
          <Route path="/api" element={<ApiData />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
