import { Menubar } from 'primereact/menubar';
import React, { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
import '../style/style.css';

const Navigation = ({ data }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const start = (
    <div className="text">
      <div className="logo">
        <h3>Maralu s.r.o.</h3>
        <p>Autodoprava & Účtovníctvo</p>
      </div>
    </div>
  );

  return (
    <div id="Navbar" className={`container ${isScrolled ? 'scrolled' : ''}`}>
      <div className={`icon ${isScrolled ? 'display-none' : ''}`}>
        <div className="left">
          <i className="pi pi-phone"></i>
          <p>+421 905 380 345</p>
        </div>
        <div className="right">
          <a href="https://www.facebook.com/maralu.sro">
            <i className="pi pi-facebook"></i>
          </a>
          <a href="#contact">
            <i className="pi pi-envelope"></i>
          </a>
        </div>
      </div>
      <button
        className="menu-toggle"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <i className="pi pi-bars"></i>
      </button>
      <div className="card container">
        <Menubar
          start={start}
          model={data}
          className={`nav-text ${isMenuOpen ? 'open' : ''}`}
        />
      </div>
    </div>
  );
};

export default Navigation;
