import React from 'react';
import '../style/style.css';
import 'primeicons/primeicons.css';

const Uctovnictvo = (props) => {
  return (
    <div id="uctovnictvo" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Učtovníctvo</h2>
        </div>
        <div>
          <p>
            Predmetom účtovníctva je aj vykazovanie skutočností o účtovných prípadoch a vykazovanie aktív a pasív v účtovnej závierke. Spravodlivo fakturujeme vykonané práce podľa skutočného počtu zúčtovaných položiek a vypracovaných výkazov. Ponúkame zodpovednosť za dodržanie zákonom stanovených termínov a lehôt. Vaša starosť je robiť biznis, naša starosť je rozsah, spôsob a preukázateľnosť účtovníctva spoľahlivo, podľa platnej legislatívy.
          </p>
        </div>
        <div className="section-items">
          {props.data
            ? props.data.map((data, index) => (
                <div key={`${data.name}-${index}`} className="item">
                  <span style={{ borderRadius: '10px' }}>
                    <i className={data.icon}></i>
                   </span>
                  <div className="description">
                    <h3>{data.title}</h3>
                    <p>{data.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Uctovnictvo;
