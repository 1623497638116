import Navigation from './navigation'; 
import Social  from './social';
import '../style/style.css';

const Footer = ({ items }) => {
  const start = <h3 className='logo' style={{ display: 'none' }}>Maralu s.r.o.</h3>;

  return (
    <div id='footer'>
      <div className='container'>
        <div className='logo-text'>
          <h3>Maralu s.r.o.</h3>
          <p>Autodoprava & Účtovníctvo</p>
          <Social className="social"/>
        </div>
        <div className="navigation" >
          <Navigation start={start} data={items} />
          <hr className='divider'/>
          <p>© Copyright Maralu.sk 2017. All right reserved. Created by graphiqstudio.eu</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
