import '../style/style.css';

const Klienti = (props) => {
  return (
    <div id="klienti">
      <div className="container">
        <div className="section-title text-center">
          <h2>Čo hovoria naši klienti</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((data, index) => (
                <div key={index} className="klienti-card">
                  <div className="klienti">
                    <div className="klienti-image">
                      <img src={data.img} alt="" />
                    </div>
                    <div className="klienti-content">
                      <p>{data.text}</p>
                      <div className="klienti-meta">- {data.name}</div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Klienti;
