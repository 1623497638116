import '../style/style.css';

const Header = (props) => {
  return (
    <header id="header" >
        <div className='background'></div>
        <div className="text-container">
          <h1>
            {props.data ? props.data.title : "Loading"}
            <span></span>
          </h1>
          <p>{props.data ? props.data.paragraph : "Loading"}</p>
          <hr className='divider'/>
          <button href="#contact" className="button">
            Kontaktujte nás
          </button>
        </div>

    </header>
  );
};
export default Header;