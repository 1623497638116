import 'primeicons/primeicons.css';
import '../style/style.css';

const Social = () => {

  return (
    <>
      <div className="social-media">
        <a href="https://www.facebook.com/maralu.sk" target="_blank" rel="noreferrer">
        <i className="pi pi-facebook" style={{ fontSize: '1.5rem' }}></i>
        </a>
        <a href="https://www.instagram.com/maralu.sk" target="_blank" rel="noreferrer">
        <i className="pi pi-instagram" style={{ fontSize: '1.5rem' }}></i>
        </a>
        <a href="https://www.linkedin.com/company/maralu" target="_blank" rel="noreferrer">
        <i className="pi pi-linkedin" style={{ fontSize: '1.5rem' }}></i>
        </a>
      </div>
    </>
  )
}

export default Social;


