import { useState, useEffect } from 'react';
import '../style/style.css';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(true); 

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-1TDF6WF9ZS";
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function() { window.dataLayer.push(arguments); }
    window.gtag('js', new Date());
    window.gtag('config', 'G-1TDF6WF9ZS');
  }, []);

  const acceptCookies = () => { 
    window.gtag('config', 'G-1TDF6WF9ZS', {
      'anonymize_ip': false,
      'allow_ad_features': true
    });
    setShowBanner(false); 
  };

  const declineCookies = () => {
    window.gtag('config', 'G-1TDF6WF9ZS', {
      'anonymize_ip': true,
      'allow_ad_features': false
    });
    setShowBanner(false); 
  };

  return (
    <div id='cookies'>
      {showBanner && (
        <div className='cookies-container'>
          <p>
            We use tracking cookies to understand how you use the product 
            and help us improve it.
            Please accept cookies to help us improve.
          </p>
          <button type="btn btn-custom" onClick={acceptCookies}>
            Accept Cookies
          </button>
          <button type="btn btn-custom" onClick={declineCookies}>
            Decline Cookies
          </button>
        </div>
      )}
    </div>
  );
}
export default CookieConsent;