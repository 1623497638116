import '../style/style.css';
import 'primeicons/primeicons.css';

const Autodoprava = (props) => {
  return (
    <div id="autodoprava" className="text-center">
      <div className="container">
        <div className="section-title text-center">
          <h2>Autodoprava</h2>
        </div>
        <div>
          <p>
            Maralu je Slovenská prepravná spoločnosť(Autodoprava) so špecializáciou na malú dopravu, ktorá ponúka svoje služby súkromníkom ale aj firmám a stále sa úspešne rozširuje a zlepšuje. Okrem dopravy zabezpečuje množstvo ďalších služieb pre naších zákazníkov.
          </p>
        </div>
        <div className="section-items">
          {props.data
            ? props.data.map((data, index) => (
                <div key={`${data.name}-${index}`} className="item">
                  <i className={data.icon}></i>
                  <h3>{data.name}</h3>
                  <p>{data.text}</p>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Autodoprava;
