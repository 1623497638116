import img1 from "../img/testimonials/01.jpg";
import img2 from "../img/testimonials/02.jpg";
import img4 from "../img/testimonials/04.jpg";
import img5 from "../img/testimonials/05.jpg";
import imgG1 from "../img/images/01-large.png"
import imgG2 from "../img/images/02-large.jpg"
import imgG3 from "../img/images/03-large.jpg"
import imgG4 from "../img/images/thUODBWLBY.jpg"
import imgG5 from "../img/images/th1-1.jpg"
import imgG6 from "../img/images/th-2.jpg"


const data = {
  "Header": {
    "title": "Autodoprava & Účtovníctvo",
    "paragraph": "Najlepšia autodoprava a účtovíctvov trenčianskom kraji"
  },
  "About": {
    "paragraph": "Maralu so svojou pôsobnosťou radí medzi malé firmy v Trenčianskom kraji. V oblasti rozvozov ale medzi najlepšie v Slovenskej republike. Máme už viac ako 20rokov skúseností v autodoprave.",
    "Why": [
      "Jednoduché  účtovníctvo",
      "Vypracovanie a kontrola DPH",
      "Účtovne uzávierky",
      "Účtovne uzávierky"
    ],
    "Why2": [
      "Expresná doprava",
      "Doprava trenčianský kraj a okolie",
      "Doprava sk a medzinárodná doprava eú",
      "Nákladná taxislužba"
    ]
  },
  "Gallery": [
    {
      "id": 1,
      "title": "NÁKLADNÁ DOPRAVA DO 3,5T",
      "image": imgG1

    },
    {
      "id": 2,
      "title": "EXPRESNÁ DOPRAVA",
      "image": imgG2
    },
    {
      "id": 3,
      "title": "DOPRAVA Z NÁKUPNÝCH CENTIER",
      "image": imgG3
    },
    {
      "id": 4,
      "title": "UCTOVNICTVO A DANOVE PORADENSTVO",
      "image": imgG4
    },
    {
      "id": 5,
      "title": "DPH ",
      "image": imgG5
    },
    {
      "id": 6,
      "title": "Project Title",
      "image": imgG6
    }

  ],

  "Klienti": [
    {
      "img": img1,
      "text": "\"Vynikajúce služby od tímu spoločnosti Maralu. S mojou zásielkou zaobchádzali opatrne a zabezpečili, že dorazí načas. Komunikácia bola jasná a včasná, vďaka čomu bol proces bez stresu. Určite by som ich odporučil každému, kto hľadá spoľahlivé prepravné služby\"",
      "name": "Martin Kratky"
    },
    {
      "img": img2,
      "text": "Služby zákazníkom v spoločnosti Maralu sú výnimočné. Tím je vždy k dispozícii, aby odpovedal na otázky a poskytoval aktualizácie o mojich zásielkach. Sú priateľskí, nápomocní a idú nad rámec toho, aby zabezpečili spokojnosť zákazníkov. Oceňujem ich odhodlanie poskytovať vysoká úroveň služieb.",
      "name": "Peter Lipovsky"
    },
    {
      "img": img4,
      "text": "Bola som ohromená profesionalitou vodičov z Maralu. Boli zdvorilí, priateľskí a skvele sa starali o môj tovar. Vozidlá boli dobre udržiavané a sledovací systém ma neustále informoval o stave mojej zásielky. Celkovo veľmi pozitívna skúsenosť.",
      "name": "Tatiana Chytilova"
    },
    {
      "img": img5,
      "text": "Bol som príjemne prekvapený konkurenčnými cenami, ktoré ponúka Maralu. Dokázali sa vyrovnať cenám iných spoločností a zároveň poskytovali vyššiu úroveň služieb. Tím reagoval na moje potreby a logistika bola dobre naplánovaná. Určite ich ešte využijem.",
      "name": "Roman Mrazik"
    }
  ],
  "Contact": {
    "address": "Maralu, s.r.o., Zafortňa 15, Bánovce nad Bebravou, 957 01",
    "phone": "+421 904 088 547; +421 905 380 345",
    "Email": "maralusro@gmail.com",
    "facebook": "fb.com",
    "twitter": "twitter.com",
    "youtube": "youtube.com"
  },
  "Autodoprava": [
    {
      "icon": "pi pi-car",
      "name": "Expresná doprava",
      "text": "Expresná doprava je služba, ktorá zabezpečuje rýchle doručenie zásielok do 24 hodín od prijatia objednávky."
    },
    {
      "icon": "pi pi-truck",
      "name": "Doprava trenčianský kraj",
      "text": "Doprava trenčianský kraj a okolie je služba, ktorá zabezpečuje rýchle doručenie zásielok do 24 hodín od prijatia objednávky."
    },
    {
      "icon": "pi pi-euro",
      "name": "Medzinárodná doprava eu",
      "text": "Medzinárodná doprava eu je služba, ktorá zabezpečuje rýchle doručenie zásielok do 24 hodín od prijatia objednávky. "
    },
    {
      "icon": "pi pi-compass",
      "name": "Nákladná taxislužba",
      "text": "Nákladná taxislužba je služba, ktorá zabezpečuje rýchle doručenie zásielok do 24 hodín od prijatia objednávky."
    },
    {
      "icon": "pi pi-box",
      "name": "Sťahovanie",
      "text": "Sťahovanie je služba, ktorá zabezpečuje rýchle doručenie zásielok do 24 hodín od prijatia objednávky."
    },
    {
      "icon": "pi pi-cart-plus",
      "name": "Doprava z nákupných centier",
      "text": "Doprava z nákupných centier je služba, ktorá zabezpečuje rýchle doručenie zásielok do 24 hodín od prijatia objednávky."
    }
  ],
  "Uctovnictvo": [
    {
      "icon": "pi pi-align-justify",
      "title": "Jednoduché a podvojné účtovníctvo",
      "text": "Komplexné externé spracovanie a kontrola jestvujúceho jednoduchého a podvojného účtovníctva."
    },
    {
      "icon": "pi pi-calendar",
      "title": "Vypracovanie a kontrola DPH",
      "text": "Vypracovanie daní a kontrola DPH."
    },
    {
      "icon": "pi pi-calendar-times",
      "title": "Účtovne uzávierky",
      "text": "Spracovanie účtovných uzávierok vrátane štátnych uzávierkových výkazov."
    },
    {
      "icon": "pi pi-chart-pie",
      "title": "Spracovanie výkazov pre štatistický úrad",
      "text": "Spracovanie výkazov pre štatistický úrad a iné štátne inštitúcie."
    }
  ],
  "Items": [
      {
        "label": "Home",
        "url": "#header"
      },
      {
        "label": "UČTOVNÍCTVO",
        "url": "#uctovnictvo"
      },
      {
        "label": "autodoprava",
        "url": "#autodoprava"
      },
      {
        "label": "Galeria",
        "url": "#gallery"
      },
      {
        "label": "O nas",
        "url": "#about"
      },
      {
        "label": "Kontakt",
        "url": "#contact"
      },
      {
        "label": "API",
        "url": "/api"
      }
  ],
  "Section3": {
    "paragraph": "Auto-Doprava s.r.o. so svojou pôsobnosťou radí medzi malé firmy v Trenčianskom kraji. V oblasti rozvozov ale medzi najlepšie v Slovenskej republike. Máme už viac ako 20rokov skúseností v autodoprave.",
    "Why": [
      "Jednoduché  účtovníctvo",
      "Vypracovanie a kontrola DPH",
      "Účtovne uzávierky",
      "Účtovne uzávierky"
    ],
    "Why2": [
      "Expresná doprava",
      "Doprava trenčianský kraj a okolie",
      "Doprava sk a medzinárodná doprava eú",
      "Nákladná taxislužba"
    ]
}
  }

export default data;