import { useState } from 'react';
import '../style/style.css';

const Gallery = ({ data }) => {  
  const [selectedImg, setSelectedImg] = useState(null);

  const handleImgClick = (img) => {
    setSelectedImg(img);
  };

  return (
    <div id="gallery" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            účtovníctvo a autodoprava v jednom mieste pre vaše podnikanie a pre vaše súkromné účely.
          </p>
        </div>
        <div className="grid">
          {data && data.length > 0 ? (
            data.map((img) => (
              <img 
                key={img.id}  
                src={img.image}  
                alt={img.title}  
                onClick={() => handleImgClick(img.image)}  
              />
            ))
          ) : (
            <p>No images available.</p> 
          )}
        </div>
        {selectedImg && (
          <div className="modal" onClick={() => setSelectedImg(null)}>
            <img src={selectedImg} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
