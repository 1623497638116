import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import '../style/style.css';

const Contact = () => {
  return (
    <div id="contact">
      <div className="contact-container">
        <div className="contact-info">
          <h2>Kontaktujte nás</h2>
          <ul>
            <li><strong>Spoločnosť:</strong> Maralu, s.r.o.</li>
            <li><strong>Adresa:</strong> Zafortňa 15, Bánovce nad Bebravou, 957 01</li>
            <li><strong>Telefón:</strong> +421 904 088 547</li>
            <li><strong>Email:</strong> maralusro@gmail.com</li>
            <li><strong>Otváracie hodiny:</strong> 9:00 – 17:00</li>
          </ul>
          <div className="social-icons">
            <a href="https://www.facebook.com/maralu.sro">
              <i className="pi pi-facebook"></i>
            </a>
            <a href="mailto:maralusro@gmail.com">
              <i className="pi pi-envelope"></i>
            </a>
            <a href="https://www.linkedin.com/company/maralu-sro">
              <i className="pi pi-linkedin"></i>
            </a>
          </div>

        </div>
        <div className="contact-form">
          <form>
            <InputText type="hidden" name="apikey" value="5abeb1d5-cc6b-447f-bafb-0727f1bd9a84" />
            <div className="form-group">
              <InputText type="text" name="name" placeholder="Name" required className="contact-input" />
            </div>
            <div className="form-group">
              <InputText type="email" name="email" placeholder="Email" required className="contact-input" />
            </div>
            <div className="form-group">
              <InputText type="text" name="subject" placeholder="Subject" required className="contact-input" />
            </div>
            <div className="form-group">
              <InputTextarea name="message" rows={5} placeholder="Message" required className="contact-input" />
            </div>
            <button type="submit" className="submit-btn">Odoslať email</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
